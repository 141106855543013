<template>
	<v-container fluid>
		<h2 class="text-center">
			{{ $t('auth.accountSetup') }}
		</h2>
		<h4 class="text-center">
			{{ $t('auth.yourFirstStep') }}
		</h4>

		<v-card max-width="600" class="mx-auto mt-5" rounded="xl">
			<v-card-title>
				<h1 class="display-1">
					{{ $t('auth.joinYourCompany') }}
				</h1>
			</v-card-title>
			<v-card-text>
				<p>{{ $t('auth.joinYourCompanyHelp1') }}</p>
				<p>{{ $t('auth.joinYourCompanyHelp2') }}</p>
			</v-card-text>
		</v-card>

		<RegisterCompanyForm />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('auth.accountSetup')
		}
	},
	components: {
		RegisterCompanyForm: () => import('@/components/auth/RegisterCompanyForm.vue')
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo'
		})
	},
	created() {
		this.open()
		if (!this.setupInfo.verified) {
			// Make sure the user is logged in or not by refreshing the token
			this.refreshAccess()
				.then(() => {
					if (this.setupInfo.joinedCompany) {
						this.$router.push({ name: 'Company' })
					}
				})
				.then(() => {
					this.close()
				})
		} else if (this.setupInfo.joinedCompany) {
			this.$router.push({ name: 'Company' })
			this.close()
		} else {
			this.close()
		}
	},
	methods: {
		...mapActions('auth', ['refreshAccess']),
		...mapActions('splash', ['open', 'close'])
	}
}
</script>
